
import Vue from 'vue';
import utils from '../../util';
import { add, sub, lightFormat, formatDistanceToNow } from 'date-fns';
import { zonedTimeToUtc } from 'date-fns-tz';

export default Vue.extend({
  name: 'configurabilityLog',
  data: (): {
    data: any; // eslint-disable-line
    search: string;
    loading: boolean;
    headers: Array<object>;
  } => ({
    data: [],
    search: '',
    loading: true,
    headers: [
      {
        text: 'Layout',
        value: 'type',
        sortable: false,
      },
      {
        text: 'Action',
        value: 'pstate',
        sortable: false,
      },
      {
        text: 'Agency',
        value: 'agencyName',
        sortable: true,
      },
      {
        text: 'Advertiser',
        value: 'advertiserId',
        sortable: true,
      },
      {
        text: 'Tactic',
        value: 'tactic',
        sortable: true,
      },
      {
        text: 'Modified By',
        value: 'LastModifiedBy',
        sortable: true,
      },
      {
        text: 'Last Modified',
        value: 'LastModifiedDate',
        sortable: true,
      },
    ],
  }),
  mounted() {
    const startDate = lightFormat(sub(new Date(), { months: 1 }), 'yyyy-MM-dd');
    const endDate = lightFormat(add(new Date(), { days: 1 }), 'yyyy-MM-dd');
    this.getActivityLog(startDate, endDate);
  },
  computed: {},
  methods: {
    formatDistanceToNow(date: Date): string {
      try {
        if (!date) return '';
        return formatDistanceToNow(date);
      } catch (err) {
        // eslint-disable-next-line no-console
        console.log('formatDistanceToNow error', date, err);
      }
      return '';
    },
    pstate(pstate: number): string {
      switch (pstate.toString()) {
        case '1':
          return '<small class="green--text">Updated</small>';
        case '-1':
          return '<small class="warning--text">Inactive</small>';
        case '0':
          return '<small class="red--text">Deleted</small>';
        default:
          return '<small class="red--text">Unknown</small>';
      }
    },
    typeIcon(type: string): string {
      switch (type) {
        case 'advertiser':
          return 'store_mall_directory';
        case 'agency':
          return 'business';
        case 'user':
          return 'person';
        default:
          return 'public';
      }
    },
    getTacticName(tactic: string): string {
      return utils.getTacticName(tactic) || 'Unknown';
    },
    async getActivityLog(startDate: string, endDate: string): Promise<void> {
      this.loading = true;
      await this.$store
        .dispatch('getActivityLog', { startDate, endDate })
        .then(data => {
          if (!data) {
            // eslint-disable-next-line no-console
            console.log('getActivityLog bad response', data);
            return;
          }
          this.data = data
            .map(x => {
              x.LastModifiedDate = zonedTimeToUtc(utils.normalizeDate(x.LastModifiedDate), 'UTC');
              return x;
            })
            .filter(x => x.type);
          this.loading = false;
        })
        .catch((error: Error) => {
          this.loading = false;
          // eslint-disable-next-line no-console
          console.error('getActivityLog error', error);
          return;
        });
    },
  },
});
