
import Vue from 'vue';
import { Theme } from '@point/utility-classes';
import utils from '../../util';
import { useLink } from '@c360/ui';

export default Vue.extend({
  name: 'WhiteLabelList',
  data(): {
    themes: Array<Theme> | null;
    headers: Array<{ text: string; value: string }>;
    search: string;
  } {
    return {
      themes: null,
      headers: [
        {
          text: 'Agency name',
          value: 'name',
        },
        { text: 'URL', value: 'domains' },
      ],
      search: '',
    };
  },
  mounted(): void {
    this.getAgencies();
  },
  computed: {
    formattedAgencies(): any {
      if (!this.$store.state.agencyAdmin.agencies) return;
      let agenciesArr = this.$store.state.agencyAdmin.agencies.map(agency => {
        agency.domain = useLink({
          product: 'analytics',
          agency: agency?.theme_code || 'agency',
          env: utils.getEnvForProductLink(),
        });
        return agency;
      });
      agenciesArr = utils.sortByProperty(agenciesArr, 'name');
      return agenciesArr;
    },
  },
  methods: {
    async getAgencies(): Promise<void> {
      await this.$store.dispatch('agencyAdmin/getAgencies');
      // if you only have 1 agency, load the details rather than show the list
      if (this.formattedAgencies.length === 1) this.openPage(this.formattedAgencies[0].originId);
    },
  },
  watch: {
    formattedAgencies: {
      handler(): void {
        this.themes = this.formattedAgencies;
      },
    },
  },
});
