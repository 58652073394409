
import Vue from 'vue';
import utils from '../../util';
import { Agency } from '../../store/modules/agencyadmin/types';
import { C360Icon, useLink } from '@c360/ui';

export default Vue.extend({
  name: 'manageModules',
  data: (): {
    id: string | null;
    name: string | null;
    searchString: string;
    agencies: Array<Agency>;
  } => ({
    id: null,
    name: null,
    searchString: '',
    agencies: [],
  }),
  components: { C360Icon },
  created(): void {
    this.getAgencies();
  },
  computed: {
    formattedAgencies(): Array<Agency> {
      if (!this.$store.state.agencyAdmin.agencies) return;
      let agenciesArr = this.$store.state.agencyAdmin.agencies.map(agency => {
        agency.domain = useLink({
          product: 'analytics',
          agency: agency?.theme_code || 'agency',
          env: utils.getEnvForProductLink(),
        });

        return agency;
      });
      agenciesArr = utils.sortByProperty(agenciesArr, 'name');
      return agenciesArr;
    },
  },
  methods: {
    async getAgencies(): Promise<void> {
      await this.$store.dispatch('agencyAdmin/getAgencies');
      // if you only have 1 agency, load the details rather than show the list
      if (this.formattedAgencies?.length === 1) this.openPage(this.formattedAgencies[0].originId);
    },
    search(): void {
      if (!this.searchString) {
        this.agencies = this.formattedAgencies;
      } else {
        this.agencies = this.formattedAgencies?.filter(agency =>
          agency.name.toLowerCase().includes(this.searchString.toLowerCase()),
        );
      }
    },
    async checkAgency(originId: string, value: boolean): Promise<void> {
      const payload = {
        originId,
        freq_viewed_networks: value,
      };
      await this.$store.dispatch('agencyAdmin/updateAgency', payload);
    },
  },
  watch: {
    formattedAgencies: {
      handler(): void {
        this.agencies = this.formattedAgencies;
      },
    },
    searchString: {
      handler(): void {
        this.search();
      },
    },
  },
});
